// Is automatically added by reactstrap, when <Card> is used in code.
.card {
  @include card;

  // Naming convention breach because of bootstrap class names (&-header, &-title, &-body, &-footer).
  &-header {
    display: flex;
    justify-content: space-between;
    background: none;
    border: none;
    padding: 1.75rem 0;
  }

  // @todo Diese Klasse kann später im Multimandanten umbenannt werden (&__title-section), da sie kein Element von React/Bootstrap ist.
  // @todo: FCP-1395 Umbau: In H3 der title-section liegen noch andere Elemente (z.B. project-implementation-state).
  // Das ist kein guter Stil. Lieber mit <Cols> arbeiten, um title-section zu strukturieren.
  .title-section {
    display: flex;
    flex-direction: column;

    &--with-image {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        margin: 0em 2.5em 0em 0em !important;
        width: 20%;
        height: 20%;
        max-width: 300px;
      }

      .title-section-text {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
      }
    }

    &--short {
      h3 {
        display: inline;
        margin: 0;
      }
    }

    // Place an element next to the title-section
    &--with-element {
      display: flex;
      flex-direction: row;
      gap: 1.75rem;
      justify-content: flex-start;
      background: none;
      border: none;

      svg {
        width: 1.563rem;
        height: 1.563rem;
      }

      // Center the check icon
      :first-child {
        margin-top: auto;
        margin-bottom: auto;
      }

      .title-section h3 {
        margin-bottom: 0.4rem;
      }

      .title-section-text {
        align-items: flex-start;
        justify-content: center;
      }

      .title-section-text h3 {
        margin: 0rem;
      }

      .actionicon {
        margin: 0rem;
        padding: 0rem;

        svg {
          opacity: 0.325;
        }
      }
    }

    &--with-icon-navigation {
      display: flex;
      justify-content: space-between;
      flex-grow: 2;
      flex-wrap: wrap;
      align-items: center;
    }

    &--my-project-card {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      align-items: center;

      .project-classification {
        margin-top: -50px;
        margin-left: -46px;
      }
    }

    // @todo Linda: Prüfen, ob man diese Klasse in eine allgemeine .text-semi-muted zusammenfasst, 
    // da dieser Klassenname sehr use-case spezifisch ist, aber zwei eher generelle Styles beinhaltet, 
    // die auch anderswo Einsatz finden könnten.
    .project-information {
      color: $color-gray;
      font-size: $font-size-sm;
    }

    .text-success {
      span {
        color: inherit;
      }
    }

    .project-implementation-state {
      display: inline-block;
      font-weight: 400;

      &__icon {
        margin-right: 0.5rem;
      }
    }

    // @todo: Wenn Ticket FCP-1395 erledigt ist, kann margin raus.
    .news-alert-symbol {
      margin-left: 0.8rem;
    }
  }

  // @todo Abgleich mit H3, benutzt man lieber .card__title oder H3, aktuell 5 Vorkommen, mal H2, mal H3.
  &-title {
    font-size: $h3-font-size;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  &-body {
    padding: 1.75rem 0;

    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child),
    h4:not(:first-child),
    h5:not(:first-child),
    h6:not(:first-child) {
      margin-top: 1em;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    padding: 1rem;
    margin: 0 -1.5rem;

    &__item {
      gap: 0.5rem;
      display: flex;
      align-items: center;
    }
  }

  &:has(.card-badge--vertical) {
    margin-bottom: 1.5rem;
  }
}

// Is neither a sub-element nor a modifier of .card, but an own react-component.
.collapsable-card {
  @include card--clickable;
  flex-grow: 0;
  margin-left: 0;
  margin-right: 0;
  min-height: 0;
  width: 100% !important;
  max-width: 1800px !important;
  min-width: 0 !important;
  // Negating card--clickable Mixing style for cursor,
  // because only CardHeader should show pointer, not CardBody
  cursor: default;

  .card-header {
    background: none;
    border: none;
    // @todo: Lösung finden, dass dieses Attribut nicht stattfinden muss
    // damit wir es nicht untendrunter negieren müssen, wenn es ein .card-badge--vertical gibt.
    align-items: center;

    &:has(.card-badge--vertical) {
      align-items: unset;
    }
  }

  .collapse .show {
    display: flex;
    flex-direction: column;
  }

  >.collapse .card-body,
  >.collapsing .card-body {
    padding: .5rem 0 1rem;
    margin-top: 0;

    >.icon-navigation {
      margin: 0 0
    }
  }
}

// @todo: Kürzen mit Mixins

.card-badge {
  &--horizontal {
    &--default {
      position: absolute;
      top: -0.8rem;
      left: -0.625rem;
      background-color: $color-gray-lighter;
      padding: 10px 1rem 0.5rem 2rem;
      box-shadow: inset 0 0 0 1px $color-gray-medium;
      height: 42px;
      font-family: $font-heading;
      font-size: 1rem !important;
      font-weight: bold;
      margin-left: 0px;
      text-align: left;
      font-weight: bold;


      &::before {
        content: "";
        width: 0;
        height: 0;
        border-top: 10px solid $color-gray-light;
        border-left: 10px solid transparent;
        top: 100%;
        position: absolute;
        left: -1px;
      }

      &::after {
        content: "";
        background-image: url("/assets/img/card-badge/card-badge--horizontal--after.svg");
        width: 18px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -10px;
      }
    }

    // Anmerkung/Ergänzung: Stand heute (93199bc) nutzt die SupportRequestFrontCard
    // direkt diese CSS-Klassen hier (je nach supportRequestDefaultStyle mit mit --light oder --default),
    // und nicht mehr die ursprünglichen support-request-card__front__body__supporter-role.
    // Was noch zu tun ist: das, was support-request-card__front__body__supporter-role-and-title 
    // geleistet hat, hier in das --light nachbauen.
    // Dafür bspw im Dokumenten-Design-Branch einfach mal am Dokument oder Chapter
    // verschiedene BadgeTypes ausprobieren, bis es klappt :)
    &--light {
      position: relative;
      background-color: $color-gray-lighter;
      padding: 0.2rem 0.5rem;
      border-radius: 1rem;
      font-family: $font-heading;
      // @todo: margin-right wird gelöscht mit Erledigung dieses Tickets:
      // https://futureprojects.atlassian.net/browse/FCP-1881 
      margin-right: 0.5rem;
      font-size: 0.8rem !important;
      margin-left: 0px;
      text-align: left;
      font-weight: bold;
    }
  }
}

// Positioning cards to each other
.card-grid-layout {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  padding: 1rem;
  align-items: flex-start;

  @media screen and (min-width: $sm) {
    grid-template-columns: 50% 50%;
  }

  @media screen and (min-width: $lg) {
    grid-template-columns: 33.3% 33.3% 33.3%;
  }

  @media screen and (min-width: $xxl) {
    grid-template-columns: 25% 25% 25% 25%;
  }

  @media screen and (min-width: $xxxl) {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
}