@import "../../node_modules/react-toastify/dist/ReactToastify";

// Leave some space for the header navigation
.Toastify__toast-container--top-right {
  top: 6em;
}

.Toastify__toast {
  border-radius: 0.5rem;
  background: white;
  color: $color-dark;
  border-left: 12px solid;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  box-shadow: 0 6px 25px 1px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: $font-size-sm;
  font-family: $font-heading;
  word-break: break-word;
}

.Toastify__toast--success {
  border-left-color: $green;
  color: darken($green, 15%);
}

.Toastify__toast--warning {
  border-left-color: $yellow;
  color: darken($yellow, 15%);
}

.Toastify__toast--error {
  border-left-color: $red;
  color: darken($red, 15%);
}

.Toastify__toast--info {
  border-left-color: $blue;
  color: darken($blue, 15%);
}

.Toastify__toast--default {
  border-left-color: $color-gray-medium;
  color: $color-dark;
}

.Toastify__close-button {
  color: $color-dark;
}

.Toastify__close-button--default {
  opacity: 0.7;
}

.Toastify__progress-bar {
  background: $color-primary;
}

.Toastify__progress-bar--default {
  background: $color-primary;
}

/* #region toastify--all-classes */
//see doc/styles.md add keyword "overwritten-external-styles" for more information

/*
.Toastify__toast-container
.Toastify__toast-container--top-left
.Toastify__toast-container--top-center
.Toastify__toast-container--top-right
.Toastify__toast-container--bottom-left
.Toastify__toast-container--bottom-center
.Toastify__toast-container--bottom-right
.Toastify__toast-container--rtl
.Toastify__toast
.Toastify__toast--rtl
.Toastify__toast-body
.Toastify__toast-icon
.Toastify--animate
.Toastify--animate-icon
.Toastify__toast-theme--dark
.Toastify__toast-theme--light
.Toastify__toast-theme--colored
.Toastify__toast--default
.Toastify__toast--info
.Toastify__toast--success
.Toastify__toast--warning
.Toastify__toast--error
.Toastify__progress-bar-theme--light
.Toastify__progress-bar-theme--dark
.Toastify__progress-bar--info
.Toastify__progress-bar--success
.Toastify__progress-bar--warning
.Toastify__progress-bar--error
.Toastify__progress-bar-theme--colored
.Toastify__close-button
.Toastify__close-button--light
.Toastify__progress-bar
.Toastify__progress-bar--animated
.Toastify__progress-bar--controlled
.Toastify__progress-bar--rtl
.Toastify__spinner
.Toastify__bounce-enter--top-left
.Toastify__bounce-enter--bottom-left
.Toastify__bounce-enter--top-right
.Toastify__bounce-enter--bottom-right
.Toastify__bounce-enter--top-center
.Toastify__bounce-enter--bottom-center
.Toastify__bounce-exit--top-left
.Toastify__bounce-exit--bottom-left
.Toastify__bounce-exit--top-right
.Toastify__bounce-exit--bottom-right
.Toastify__bounce-exit--top-center
.Toastify__bounce-exit--bottom-center
.Toastify__zoom-enter
.Toastify__zoom-exit
.Toastify__flip-enter
.Toastify__flip-exit
.Toastify__slide-enter--top-left
.Toastify__slide-enter--bottom-left
.Toastify__slide-enter--top-right
.Toastify__slide-enter--bottom-right
.Toastify__slide-enter--top-center
.Toastify__slide-enter--bottom-center
.Toastify__slide-exit--top-left
.Toastify__slide-exit--bottom-left
.Toastify__slide-exit--top-right
.Toastify__slide-exit--bottom-right
.Toastify__slide-exit--top-center
.Toastify__slide-exit--bottom-center
*/

/* #endregion toastify--all-classes */