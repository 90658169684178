// styles for the document-module

.document {

  // Style the editable title of a document.
  .card-badge--horizontal--default+.title-section {
    width: 100%;

    form {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 3rem;

      :first-child {
        margin: 0 !important;
        flex: 3 2 90%;
      }

      :last-child {
        flex: 1 1 10%;

        .btn-flex-layout {
          flex-wrap: nowrap;
        }
      }
    }
  }

  // section with "order chapters switch" and "delete document icon"
  .control-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    .icon-navigation {
      margin: 0 2rem 0 0;
    }

    &__pdf-view {
      display: flex;
      flex-direction: row;

      span {
        display: flex;
        padding-top: .25rem;
      }

      height: fit-content;
    }
  }
}

.document-description {

  // display the description next to the info icon
  .editable {
    display: flex;

    svg {
      margin-right: 1rem;
    }
  }
}

.chapter-card {
  margin-bottom: 2rem;
}

// TODO: klarer benennen: dieser Style hängt an der Card, nicht am Header, 
// soll aber vermutlich Wirkung auf den Header haben (in einer Card im Edit-Modus)
.chapter-header {

  // The title input field of a chapter fills up all available space till the checkbox: 'marking the steps as fulfilled'.
  .title-section {
    flex-grow: 1;
    margin-right: 2rem;

    input {
      width: 100%;
    }
  }
}

// TODO: präziser benennen: Klasse hängt nur im Edit-Mode am Content, nicht im Anzeige-Mode
// includes rte-formField and checklist
.chapter-content {
  display: flex;
  gap: 1rem;

  &__form-field-on-open-checklist {
    // make 15% space for the checklist
    width: 85%;

    // Reset the textarea, so that it appears next to the checklist, when the checklist is open.
    .form-rte {
      margin-right: 0rem !important;
      padding-right: 0rem !important;
      border: none !important;
    }
  }

  &__form-editMode {
    flex-grow: 1;
  }


  // Fix size for text with option to resize by the user.
  .quill {
    height: 50vh;
    width: 100% !important;
    resize: vertical;
    overflow: auto;
  }

  // This Style is responsible, that the button for opening/closing the checklist
  // overlaps the textarea.
  .form-rte {
    margin-right: -7.3rem;
    border-right: 1px solid #F3F3F3;
    padding-right: 1rem;
  }

  &__checklist {
    background-color: #ffffff;
    border-right: 1px solid #F3F3F3;
    position: relative;
    height: fit-content;
    margin-bottom: 1rem;

    &--open {
      height: auto;
      padding: 0.5rem 0rem 1rem 1rem;

      // avoid changing size of checklist on entries with a long title
      width: calc(40% + 25px);

      border: 1px solid #F3F3F3;
      border-radius: 0 1rem 1rem 1rem;

      .chapter-content__checklist__btn {
        top: -1px;
      }
    }

    // Align the svg with the button and style it like the button.
    .make-yellow,
    .make-green {
      border-style: solid;
      border-color: #F3F3F3;
      border-width: 1px 1px 1px 0;
      padding: 0.25rem 0.1rem 0.25rem 0;
      // The goal is, that only the button (for opening/closing the checklist) has no right border, but
      // the checklist itself has a right border
      // (@see style template https://www.sketch.com/s/625712e1-e02a-4aba-863c-66ab8f4615db/prototype/CA070CE5-3628-413A-9EF7-45CE2138B07B/a/CA070CE5-3628-413A-9EF7-45CE2138B07B?resizeMode=Fit). 
      // And because the button is a child of the checklist,
      // the button has to override the right border color, which should match the background color
      // to be appear as no border has been set for the right side.
      border-right-color: #ffffff;
      margin-right: -1px;
    }

    // Nicht sicher ob das so umgesetzt wird oder ob vom Coder hier schon ein
    // .btn kommt, den wir stylemäßig anpassen an den Entwurf.
    &__btn {
      width: 39px;
      height: fit-content;
      position: absolute;
      right: 0;
      left: -39px;
      top: 0px;
      background-color: #ffffff;
      border-style: solid;
      border-color: #F3F3F3;
      border-width: 1px 0px 1px 1px;
      border-radius: 1rem 0 0 1rem;
      padding: 0.25rem;

    }

    &__checkbox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 0.25rem;


      // #region action icon handling

      // special handling of the ActionIcon:
      // Usually we un-display an (Action)Icon by React onMouseEnter/onMouseLeave.
      // In this case, there are "ActionIcon artefacts" when un-hovering the checklist area:
      // the ActionIcon still was visible.
      // To avoid this, we do the following:
      // 1. ActionIcon is ALWAYS rendered
      // 2. ActionIcon is un-displayed/hidden by default
      // 3. ActionIcon is displayed on hover on the checklist item

      .actionicon {
        display: none;
      }

      &:hover {
        .actionicon {
          display: flex;
        }
      }

      // #endregion action icon handling

      &--user-entry {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 0.25rem;
        padding-bottom: 0.5rem;

        // #region action icon handling

        // special handling of the ActionIcons within the icon-navigation in edit mode:
        // Usually we un-display an (Action)Icon by React onMouseEnter/onMouseLeave.
        // In this case, there are "ActionIcon artefacts" when un-hovering the checklist area:
        // the ActionIcon still was visible.
        // To avoid this, we do the following:
        // 1. ActionIcon is ALWAYS rendered
        // 2. ActionIcon is un-displayed/hidden by default (but not in edit mode)
        // 3. ActionIcon is displayed on hover on the checklist item
        .icon-navigation:not(.editMode) {
          display: none;
        }

        &:hover {
          .icon-navigation {
            display: flex;
          }
        }

        // #endregion action icon handling

        .icon-navigation {
          // Save and trash icon will appear on the same row/line.
          flex-wrap: nowrap;
          // Icons should appear in the center of the row, aligned with the input field.
          margin: 0;
          gap: 0;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      padding: 1rem;
      border: 1px solid #F3F3F3;
      border-radius: 1rem;

      .checklist-content-item ul {
        list-style-type: none;
        padding: 0rem;
      }
    }
  }
}

.document {
  .icon-navigation {
    .btn {

      // Hover-Effekt für die Buttons im Header der CollapseCard.
      // Dieser Effekt ist standardmäßig nicht sichtbar, siehe nvaigation.scss, Zeile 40 bis 54.
      &:hover {
        background-color: $color-gray-light;
        border: none;
      }
    }
  }
}

.chapter-text-examples {

  // @todo: Vorschlag zur Verallgemeinerung: Modals bekommen immer ein 
  // Cancel-ActionIcon, wenn es keinen Schließen- & Abbrechen-Button gibt.
  .modal-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &__body {
    margin-top: 1.5rem;
    display: flex;

    &__list {
      display: flex;
      flex-direction: column;
      min-width: 16rem;
      margin-left: -2.5rem;

      &__button {
        border-radius: 0px 9999px 9999px 0px;
        border: none;
        margin-bottom: 0.5rem;

        &--selected,
        &:hover,
        &:active {
          background-image: $background-image;
          color: $color-on-primary !important;
          border: none;
        }
      }
    }

    &__content {
      height: 70vh;
      scroll-behavior: auto;
      overflow-y: scroll;
      margin-left: 2rem;
      padding-right: 1rem;
    }
  }
}

// Styles the check-summary button, 
// used within the checklist summary and within the collapseCard of a chapter (view and edit mode).
.check-summary-icon-with-text {
  display: flex;
  font-size: 0.875rem;
  padding: 0.4rem 1rem;
  gap: 0.5rem;
  border: 1px solid $color-dark !important;
  border-radius: 9999px;
  font-weight: 700;
  height: 2.125rem;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

// Styling the summary of a chapter shown in a form.
.chapter-template-summary {

  // The h3 tag is left and on the right side is the cancel button.
  .modal-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    h3 {
      text-align: left;
    }
  }

  form {
    margin-top: 1rem;
    // Diese Größe des Forms innerhalb der Klasse ist so angepasst, 
    // dass die beiden Modals, welche die Klassen `.chapter-text-examples` und `.chapter-template-summary` jeweils verwenden,
    // die gleiche Größe haben.
    height: 59.7vh;
    scroll-behavior: auto;
    overflow-y: scroll;
    padding-left: 0.5rem;

    .checklist-content-item {
      ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        gap: 2rem;
        flex-wrap: wrap;

        li {
          // The goal is to show maximum 3 items per row.
          flex: 1 0 20%;
          margin: 1rem;
          width: 5rem;
        }

        strong {
          font-size: 1rem;
          font-weight: normal;
        }

        p {
          color: $color-gray-light;
          font-size: 0.875rem;
          font-weight: normal;
        }
      }
    }

    .chapter-content {
      &__checklist {
        &__checkbox {
          display: flex;
          flex-direction: column;

          // Style the label for the checkbox as a small header tag accordingly to the style template.
          .form-check-label {
            font-family: $font-heading;
            font-size: 1.2rem;
            font-weight: 700;
          }

          gap: 0.75rem;
        }
      }
    }
  }
}