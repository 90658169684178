// @todo: was macht .common-form? Braucht es diese allgemeine Klasse? Was unterscheidet Forms mit .common-form von anderen Forms? 
.common-form {

  .form-text {
    color: $color-gray;
    font-size: $font-size-sm;
    font-weight: normal;
  }

  .card-header {
    h3 {
      font-size: $h3-font-size;
      margin-bottom: 1rem;
    }

    // tall input field for headlines, usually used without label, e.g. project-title in project profile form
    .input-headline {
      width: 100%;

      input {
        width: 50%;
        border: none;
        outline: none;
        font-size: $h3-font-size;
        color: $color-dark;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
    }
  }

  .card-body {
    padding-bottom: 2rem;

    h3 {
      font-weight: bold;
      font-size: 1.25rem;
      margin-bottom: 0.125rem;
    }

    h3+div {
      margin-top: 0.75rem;
    }

    .description {
      color: $color-gray;
      font-size: 0.875rem;
      font-weight: normal;
      margin-bottom: 0.5rem;
    }
  }
}

// #region Error + Button Area on forms

// @todo Linda: wie kann man das testen? Norbert: Da es in jedem Formular benutzt wird: vermutlich einfach mal einen Fehler provozieren.
.general-form-error-container {
  color: $danger;
  display: block;
  font-weight: bold;
}

.error-and-buttons-container {
  text-align: right;

  .btn-flex-layout {
    justify-content: right;
  }
}

.elements-next-to-FormButtonsAndGeneralErrorArea {
  display: flex;

  // negating row-styles that are part of FormButtonsAndGeneralErrorArea
  .row {
    grid-gap: unset;
    margin: unset;
  }
}

// @todo: lassen sich die nächsten Styles anders lösen (z.B. className="mb-2" im Code)
.form-general-error {
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0rem;
  }
}

// #endregion Error + Button Area on forms

.select-form {
  width: fit-content !important;
}

.radio {
  font-weight: normal;
  font-size: 1rem;
  padding-left: 0.2rem;
}

.radio-icon--chosen {
  background-color: $color-gray-lighter !important;
  box-shadow: inset 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
}

input[type=number] {
  width: auto;
}

label {
  font-weight: bold;
  font-size: $font-size-lg;
  margin-bottom: 0.125rem !important;
}

// bootstrap class
// styles the explanation text below the label of a form field
.form-text {
  margin: 0 0 0.5rem;
  display: block;
}

/* #region text-inputs */

// bootstrap classname
// one line of input text-field
.form-control {
  background-color: $color-gray-lighter;
  border: none;

  &:focus-visible {
    @include focus-visible--forced;
  }
}

textarea {
  height: 5.5rem;
}

/* #endregion text-inputs */

/* #region checkboxes */

// gradient style for checkboxes and custom icon
// @see: https://dev.to/themodernweb/checkbox-css-style-gradient-checkbox-with-pure-css-custom-input-css-3gko
.form-check-input[type="checkbox"] {

  border-color: $dark !important;

  &:checked {
    background: $background-image;
  }

  &:checked::before {
    display: flex;
  }

  &::before {
    content: '';
    display: none;
    width: 100%;
    height: 100%;
    background-image: url(/assets/icons/check-simple.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

// bootstrap class
// explanation of bootstrap-class-names relating to checkboxes:
// .form-check styles a container/group of checkboxes 
// .form-label styles the container of checkbox and label
// .form-check-label styles the label, which is automatically defined within a <Label check> Tag
// .form-check-input styles the checkbox itself
.form-check {
  display: grid;
  margin-bottom: 0 !important;

  &-inline {
    display: flex;
    gap: 1rem;
  }

  // unset Bootstrap margin
  .mb-3 {
    margin-bottom: 0 !important;
  }
}

// bootstrap class
.form-check-label {
  font-weight: normal;
  font-size: 1rem;
}

/* #endregion checkboxes */