/* #region focus:visible */

// for all clickable elements that are not addressed by default oder with a different default box-shadow
@mixin focus-visible--forced {
  box-shadow: $box-shadow-focused !important;
}

// for all clickable elements without box-shadow in :focus-visible
// and for SVGs without a-tag, e.g. "?"-faq-icon in collapsable-card header (inactive project)
@mixin focus-visible--no-box-shadow {
  box-shadow: none !important;
  color: $color-primary !important;
}

// for all clickable elements where box-shadow with "inset" attribute is overlapped
@mixin focus-visible--no-overlapping {
  box-shadow: 0 0 0 3px $color-primary !important;
}

/* #endregion focus:visible */

/* #region common */

@mixin editable {
  background: $color-white !important;
  cursor: url('/assets/icons/pencil.svg') 0 15, auto !important;
}

/* #endregion common */

/* #region cards */

// Only to be included in all styles-classes of HTML elements that are not <Card>,
// since only <Card> is automatically decorated with .card by reactstrap
@mixin card {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  flex-grow: 1; // @todo: Card wird durch diese Einstellung nach unten vergrößert bei schmaler Fensterbreite, 
  // abhängig ob CollaspeCards auf- oder zugeklappt sind: members.tsx, Card Projektbefürwortungen
  border-radius: 0.625rem;
  padding: 0 1.5rem;
  margin-bottom: 0.5rem;
  box-shadow: none;
  border: solid 1px $color-gray-light;

  @media screen and (min-width: $md) {
    margin-bottom: 1rem;
  }
}

@mixin card--clickable {
  box-shadow: $box-shadow-default;
  border: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    box-shadow: $box-shadow-hover;
  }
}

// The actual style of SupportRequestCard (--default, --light, ...)
// is determined in SupportRequestCard.tsx using the value of SUPPORT_REQUEST_CARD_STYLE env variable
@mixin support-request-card {

  // limits width in case supportRequestCard is not displayed in grid
  max-width: 40rem;
  height: 100%;
  min-height: 300px;
  border-radius: 0.625rem;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 8rem;
    z-index: -1;
    border-radius: 0.625rem 0.625rem 0 0;
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    border-radius: 0.625rem;
  }

  .support-request-card {

    &__front {

      &__body {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
        flex-direction: column;

        // background-color is set within the code, changing the opacity, to have a card color depending on chosen SupporterRole
        // @see: SupportRequestCard
        &__request-title {
          &>h3 {
            font-family: $font-heading;
          }
        }
      }

      &__content {
        font-weight: bold;
      }

      &__data-and-actions {
        font-weight: normal;
      }

      &__supportoffer-popup-button {
        text-align: center;
      }
    }
  }

  &__back {
    &__body {
      display: flex;
      flex-direction: column;
    }
  }
}

/* #endregion cards */

/* #region buttons */

@mixin btn {
  border-radius: 9999px;
  font-family: $font-heading;
  padding: 0.8rem 1.25rem;
  font-size: 1.125rem;
  line-height: 1.1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  color: $color-dark;
  user-select: none;
  transition: 0.3s;
  vertical-align: middle;
  border: solid 1px $color-white;
  background-color: $color-gray-lighter;
  transition: 0.3s;
  cursor: pointer;
  gap: 0.5rem;
  text-decoration: none;
  width: auto;

  &:disabled {
    opacity: .4;
    cursor: default;
    color: inherit;
  }

  &:hover {
    background-color: $color-gray-light;
    border: solid 1px $color-white;
    color: $color-dark;
    text-decoration: none;
  }

  &:focus-visible {
    color: inherit;
    // bootstrap changes the background-color in focus:visible for all btn
    // revert removes this overwriting style 
    background-color: revert;
  }

  &:active {
    color: inherit !important;
  }
}

@mixin filteritem {
  transition: 0.2s;
  border: none;
  display: inline-block;
}

/* #endregion buttons */