//this file contains all styles for the partnermarket: SupportRequests

.new-support-request__form {
  display: flex;
  gap: 1rem;
  flex-grow: 1;

  .col {

    &:first-child {
      flex-grow: unset;
      flex-basis: 35%;
    }

    &:nth-child(2) {
      @include card;
      padding: 1.5rem;
    }
  }
}

.supporter-role-card {
  @include card--clickable;
  font-size: 1.25rem;
  font-family: $font-heading;
  font-weight: 700;
}

.support-request-card {
  &--default {
    @include support-request-card;

    padding: 0 1rem;

    &:before {
      background-image: $gradient-of-color-secondary;
    }

    &:after {
      background-image: $gradient-of-color-secondary;
    }

    .support-request-card {

      &__front {

        &__body {
          padding: 3rem 1rem 2rem 1rem;

          &__request-title {
            margin: auto;

            &>h3 {
              font-size: 1rem !important;
              font-weight: bold;
            }
          }

          &__supporter-role-picture {
            text-align: center;
            margin: auto;
          }

          &>hr {
            border-top: 1px solid $color-gray;
            width: 35%;
            margin-left: 0;
          }
        }
      }

      &__back {
        &__body {
          padding: 3rem 1rem 2rem 1rem;
          gap: 0.5rem;
        }
      }
    }

  }

  &--light {
    @include support-request-card;
    padding: 0 0.5rem;

    &:before {
      background-image: radial-gradient(circle at top left, $color-white, $color-white);
    }

    &:after {
      background-image: radial-gradient(circle at top left, $color-white, $color-white);
    }

    .support-request-card {
      &__front {
        &__body {
          padding: 1rem 1rem 2rem 1rem;

          // @todo Linda: durch den Umbau der Badge-Logik musst/darfst du eine Lösung finden,
          // dieses Element hier loszuwerden.
          // Ursprünglich war das "horizontal--light"-Badge ja nur in der SupportRequestFrontCard,
          // und dort nochmal in diese Klasse hier geschachtelt.
          // Diese Schachtelung fällt weg mit der Integration der Badges
          // in die CollapseCard.
          // Beide Änderungen sind im gleichen Commit wie dieser Kommentar :)
          // 
          // &__supporter-role-and-title {
          //   display: flex;
          //   gap: 0.5rem;
          //   align-items: baseline;
          //   position: relative;
          //   margin-left: 0;
          //   text-align: left;
          // }

          &__request-title {
            display: flex;
            gap: 0.5rem;
            align-items: baseline;

            &>h3 {
              font-size: 0.8rem !important;
              font-weight: normal;
            }
          }

          &__content {
            margin-top: 1rem;
          }

          &__data-and-actions {
            margin-top: 1.5rem !important;
            font-size: 80%;

            .row {
              margin: 0 0 0.5rem 0;
            }
          }

          &__supportoffer-popup-button {
            text-align: left;
          }
        }
      }

      &__back {
        &__body {
          padding: 1rem 1rem 2rem 1rem;
          gap: 0;

          h5,
          p {
            font-size: 0.8rem;
          }

          .btn-flex-layout {
            gap: 0 !important;

            .btn {
              scale: 80%
            }
          }
        }
      }
    }
  }

  &--not-public {
    &.support-request-card--default {
      filter: saturate(0);
    }

    &.support-request-card--light {
      filter: brightness(95%);
    }
  }
}