.project-resources {

  /* show border only if there are other rows before (finances view) */
  &__total-sum:not(:first-child) {
    border-top: 1px solid $color-gray;
    margin-top: 0.5em !important;
    padding-top: 0.5rem;
  }

  // @todo Linda: alle Rows sollen unten eine Margin haben und wie default Rows funktionieren, sonst braucht es keine Rows. Wie kann man das hier auflösen?
  .row {
    margin: 0;
  }

  .total-card {
    font-weight: 600;
    margin-top: 1em;
    text-align: right;

    &__sum-line {
      flex-wrap: nowrap;
    }
  }

  .work-package {

    &-card {
      padding: 1em;

      .card-header {
        padding: 0;
      }

      // contains add-task-button and work-package-card__sum
      &__footer {
        margin-top: 2em;
        align-items: center;
      }

      &__sum {
        font-weight: 600;
        font-size: 1.1em;
        justify-content: flex-end;
      }
    }
  }

  .task-card {
    &__title {
      padding: 1em 0.8em;

      // @todo Linda: :focus-visible funktioniert noch nicht!
      &:focus-visible {
        @include focus-visible--forced;
      }
    }

    &__footer {
      border-bottom: 1px solid $color-gray;
      padding: 1rem 1rem 1.5rem;
      margin-bottom: 1rem;
    }
  }

  .resource-card {
    @include card--clickable;

    &__title {
      flex-grow: 2;
    }

    &__icon-navigation {
      justify-content: flex-end;

      @media screen and (min-width: 768px) {
        margin-bottom: 0 !important;
      }
    }

    // @todo Linda: diese Zeile kann eigentlich weg, nur werden bei mehreren .card-body innerhalb einer .collapsable-card alle instanzen angesprochen, auch der .card-body in der resource-card. Anders lösen (:first-child)?
    .card-body {
      padding: 1.75rem 0;
    }

    // @todo Linda: Warum greift hier :focus-visible nicht automatisch? 
    &:focus-visible {
      color: $color-primary;
      box-shadow: $box-shadow-focused;
    }
  }

  .material-cost-dropdown {
    margin: 1em 0 0 1rem;
  }
}