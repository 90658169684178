// @todo: das FlippingElement ist per Tastatur ansteuerbar. Aber es gibt einen suboptimalen optischen Response,
// wenn man das tut: https://futureprojects.atlassian.net/browse/FCP-1233
// Wird aktuell eingesetzt bei den SupportRequestCards, sollte aber idealerweise allgemein einsetzbar sein.
// Die Frage ist: welche Anforderungen ergeben sich daraus für die eingesetzten Elemente, die hier geflippt werden sollen?
// die Antwort darauf sollte in FlippingElement.tsx und/oder hier dokumentiert werden
//
// Entstehung orientiert an:
// https://www.w3schools.com/howto/howto_css_flip_card.asp
// https://www.youtube.com/watch?v=NCLdf661ILE

.flipping-container {

  &__front-element {
    @include card--clickable;
    border-radius: 0.625rem;
    position: relative;
    -webkit-backface-visibility: hidden; // Safari
    backface-visibility: hidden;
    transition: 0.2s;
    // negating card styles for background-visibility of request-card 
    background-color: unset;
    padding: unset;
  }

  &__back-element {
    @include card--clickable;
    border-radius: 0.625rem;
    position: absolute;
    background-color: $color-white; // hides the "flipped" content of the "other card" behind a colored background
    -webkit-backface-visibility: hidden; // Safari
    backface-visibility: hidden;
    top: 0;
    height: 100%;
    transform: rotateY(180deg);
    padding: unset;
  }

  &--flipped {
    transform: rotateY(-180deg);
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  &--flipped-to-front {
    transform: rotateY(0deg);
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  &:focus-visible {
    @include focus-visible--no-overlapping;
  }
}