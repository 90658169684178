.marketplace-search-container {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 64rem;
  width: 100%;

  .marketplace-searchbar {
    background-color: white;
    box-shadow: $box-shadow-default;
    padding: 0.75rem 2rem 0.75rem 3rem;
    border-radius: 9999px;
    font-family: $font-heading;
    font-weight: 500;

    &::placeholder {
      opacity: 0.75;
    }

    &:focus-visible {
      @include focus-visible--forced;
    }
  }

  .icon {
    position: absolute;
    left: 1rem;
    cursor: pointer;
  }
}

.search-statistics {
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  font-family: $font-heading;
  gap: 0.5rem;

  &__count {
    font-size: 1.5rem;
  }
}

.filtering {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__collapse-show {
    max-width: 80%;
  }

  &__btn {
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 1rem;
    border: none !important;

    &:hover,
    &:active {
      border: none !important;
    }

    &--active {
      box-shadow: inset 0px 0px 0px 2px $color-dark !important;
      border: none;

      &-icon {
        transform: rotate(180deg);
      }
    }
  }
}

/* #region styles for specific markets */

// project-market
.market-info-card {
  background-color: rgba(255, 255, 255, 0.75);
  transition: 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.6) !important;
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: $gradient-of-color-secondary;
    border-radius: 0.625rem;
  }
}

/* #endregion styles for specific markets */


/* unused styles for unused MarketResultsTab.tsx
// @todo: wo sollen solche styles hin? styles-graveyard.scss wurde schon mal angelehnt
  .market-tab+.market-tab {
    margin-left: 0.5rem;

    @media screen and (min-width: $sm) {
      margin-left: 1.25rem;
    }

    @media screen and (min-width: $md) {
      margin-left: 2rem;
    }

    @media screen and (min-width: $xl) {
      margin-left: 5rem;
    }
  } */