.dashboard-flex-layout {
  overflow: visible;
  width: 100%;
}

.dashboard-card {
  width: 300px;
  padding: 0;
  text-align: center;
  margin: 1rem;
  position: relative;
  float: left;
  flex-grow: 1;
  border-radius: 0.625rem;

  .card {
    margin-bottom: 0;
  }

  &--clickable {
    @include card--clickable;
  }

  &__header {
    border-bottom: 1px solid $color-gray-light;
    margin: 1rem -1.5rem;
    padding: 0 1rem;
  }

  &__body {
    padding: 1rem 0;
    text-align: center;
  }

  &__data {
    font-size: 2rem;
  }

  &--large {

    @media screen and (min-width: $md) {
      width: 640px;
    }
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 3px $color-primary !important;
  }
}

.statistics-wheel {

  &__outer {
    stroke: $color-primary;
    fill: none;
  }

  &__inner {
    stroke: $color-gray-lighter;
    fill: none;
  }
}