.market-tabs {

  margin: 0 -3rem 2rem -3rem;

  .nav-item {
    font-family: $font-heading;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: -4px;
    padding: 0.3rem 0.7rem 0.3rem 0.3rem;

    &.active,
    &:hover {
      transition: all 0.175s ease-in-out;
      border-bottom: 4px solid;
      border-image: $background-image;
      border-image-slice: 1 0;
    }

    a {
      border-bottom: none;
      border-image: none;
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  .nav-tabs {
    display: flex;
    gap: 1rem;
    border-bottom: 4px $color-gray-lighter solid;

    @media screen and (min-width: $md) {
      gap: 5rem;
    }
  }
}

.icon-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0.625rem 0;
  gap: 0.7rem;

  .btn {
    &:hover {
      // TODO Linda Warum ist das ausgeschalten? Auf der Dokumentenseite wäre es gut,
      // wenn die Buttons in den CollapseCard einen HoverEffekt hätten....
      // In den Coder-Styles wurde ein entsprechender Hover-Effekt hinzugefügt....
      background-color: transparent;
      border: none;
    }
  }

  // @todo Linda: Sobald ProposalDetailsCard überarbeitet ist, kann diese Klasse vermutlich aufgelöst werden, Ticket: FCP-982
  &--with-confirmation-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0.625rem 0;
    grid-gap: 0.7rem;
    gap: 0.7rem;

    @media screen and (min-width: $xl) {
      justify-content: space-between;
    }
  }
}

/* #region page-navigation */

.page-navigation-area {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  height: 2rem;
  justify-content: space-between;
}

.page-navigation-btn {
  background-color: $color-gray-lighter;
  font-weight: bold;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem;
  border: solid 1px $color-white;
  min-height: 36px;

  &:hover {
    background-color: $color-gray-light;
  }

  // undo :hover for disabled page-navigation-btn
  &:hover:disabled {
    background-color: $color-gray-lighter;
  }

  &__text {
    display: none;

    @media screen and (min-width: $md) {
      display: block;
    }
  }
}

/* #endregion page-navigation */