.idea-card--clickable {
  @include card--clickable;
}

/* #region common projects card */
.project-card {
  @include card--clickable;

  .card-header {
    display: block;
  }

  &__thumbnail {
    margin: -1.75rem -1.5rem 1.5rem -1.5rem;
    border-bottom: 1px solid $color-gray-light;

    img {
      border-radius: 0.563rem 0.563rem 0 0;
      width: 100% !important;
      height: auto !important;
      min-width: unset !important;
      max-width: unset !important;
      min-height: unset !important;
      max-height: 12rem !important;
      object-fit: cover !important;
    }

    // for Safari only
    @media screen and (-webkit-min-device-pixel-ratio: 0) {

      _::-webkit-full-page-media,
      _:future,
      :root,
      img {
        top: 12rem !important;
      }
    }
  }

  .dropdown {
    display: flex;
  }

  &--empty {
    border: 3px dashed #cccccc;
    box-shadow: none;

    .card-body {
      display: flex;
      flex-grow: 1;
      margin-bottom: 2rem;
      padding: 0;
      font-size: 1.125rem;
      font-family: $font-heading;
      font-style: italic;
      font-weight: 400;
    }
  }

  .project-classification {
    position: absolute;
    right: -1.7rem;
    top: -1.3rem;
  }
}

/* #endregion project-card */

/* #region team-video */

.teamvideo {
  display: flex;
  justify-content: center;
}

/* #endregion team-video */

/* #region followership */

.followership-card-layout {
  display: flex;
  gap: 0.2rem 1rem;
  flex-wrap: wrap;
}

.followership-card {
  @include card--clickable;
  padding: 0px;

  &__body {
    padding: 0.5rem !important;
  }

  .round-icon-btn {
    margin-left: 0.5rem;
    scale: 0.8;
  }
}

/* #endregion followership */