// all icons embedded as links or btn
.actionicon {
  margin-left: 0.5rem;
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;

  a {
    border: none;
    box-shadow: none;
  }

  &:hover:not(.disabled) {
    svg {
      color: $color-primary !important;
    }
  }

  &:focus-visible:not(.disabled),
  &:focus-visible:not(.disabled) svg {
    @include focus-visible--no-box-shadow;
  }

  .left {
    margin-left: 0;
    margin-right: 1rem;
  }

  svg {
    width: 1.563rem;
    height: 1.563rem;
  }

  &.disabled,
  &.disabled:hover {
    background-color: $color-gray-lighter;
    color: $color-gray-medium;
  }
}

// all not clickable icons, eg. goals in project profile
// does NOT define all icons that are not actionicons, rather only non-functional image-icons
.non-action-icon {
  svg {
    margin: auto 0.75rem auto 0;
  }

  &--left {
    margin-right: 0.75rem;
  }

  &--right {
    margin-left: 0.75rem;
  }
}

.ordericon-wrap>button {
  position: relative;
  padding: 0em;
  background-color: transparent;
}

// @todo Linda: :focus-visible funktiinert noch nicht
.ordericon {
  &--up {
    transform: rotate(90deg);
    left: 36%;
    bottom: 27%;
    border: none !important;
    background-color: transparent !important;
  }

  &--down {
    transform: rotate(270deg);
    top: 34%;
    left: -14%;
    border: none !important;
    background-color: transparent !important;
  }

  &--right {
    transform: rotate(180deg);
    right: 50%;
    border: none !important;
    background-color: transparent !important;
  }

  &--left {
    transform: rotate(180deg);
    right: 7%;
    border: none !important;
    background-color: transparent !important;
  }

  &--up:hover,
  &--down:hover,
  &--left:hover,
  &--right:hover {
    color: $color-primary;
  }

  &--up:disabled,
  &--down:disabled,
  &--left:disabled,
  &--right:disabled {
    color: $color-gray;
  }


  &--up:active,
  &--down:active,
  &--left:active,
  &--right:active {
    color: $color-primary;
  }

  &--up:focus,
  &--down:focus,
  &--left:focus,
  &--right:focus {
    color: $color-primary;
  }

}

.chevron-right {
  margin-left: 1.625rem;

  &--toggled {
    transform: rotate(90deg);
    transform-origin: 50% 50% 0;
  }
}

.chevron-left {
  margin-left: 0rem;
}

.chevron-right,
.chevron-left {
  transition: all ease-in-out 0.25s;
  height: fit-content;
}

// small dot attached to elements to show news
// used by AlertSymbol
.news-alert-symbol {
  height: 15px;
  width: 15px;
  background-image: $background-image;
  border-radius: 50%;
  display: inline-flex;

  // position used on actionicons
  &--on-icon {
    position: relative;
    top: 0.7rem;
    right: 1.2rem;
  }
}