// sponsoring elements to be bouncing in
// especially for SINN-sachsen
.sponsors {
  position: absolute;
  z-index: 8;
  right: 0;
  bottom: 10rem;
  overflow: hidden;
  padding: 2rem;
  transition: right .2s ease-in-out;

  &__item {
    // animation
    animation-name: bounceInRight;
    animation-iteration-count: 1;
    animation-duration: .8s;
    animation-delay: 2s;
    transform: translateX(120%);
    animation-fill-mode: forwards;

    // design
    border-radius: 1rem;
    border: 2px solid #000;
    box-shadow: 5px 5px 0 0 #000;
    background: $color-white;
    padding: 1.5rem;
    margin-top: 1rem;
    position: relative;
    transition: right .2s ease-in-out;
    max-width: max-content;
    margin-left: auto;
    margin-right: 0;
  }
}

// animation definition for bouncing in of an element from right
// @see: https://www.tutorialspoint.com/css/css_animation_bounce_in_right.htm
@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
  }

  80% {
    -webkit-transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}